export const stashruns = "Stashrun's ";
export const inputBaseClass = "w-full mb-1 font-secondary";
export const heading = {
  loginSignUpPage: "Login or Sign up",
  setYourPassword: "Set your password",
  enterPassword: "Enter password",
  forgotPassword: "Forgot password",
  checkYourEmail: "Ok, check Your Email",
  createNewPassword: "Create a new password",
  changePassword: "Update password",
  enterYourName: "Enter your name",
  whenYourBirthday: "When's your birthday?",
  addPhoto: "Add your profile photo",
  confirmLooksGood: "Make sure you look good!",
  looksGood: "Everything look good?",
  editProfile: "Edit Profile"
};
export const subHeading = {
  loginOrSignup: "Play games to fund charities, together with your friends and favorite celebs.",
  forgotPassword: "We'll email you a link you can use to reset your password.",
  newPassword: "Your new password must be different from the previous used passwords."
};

export const emailAttributes = {
  label: "Email",
  name: "email",
  placeHolder: "example@gmail.com",
  className: inputBaseClass
};

export const setPasswordAttributes = {
  label: "Password",
  name: "password",
  placeHolder: "Enter Password",
  className: inputBaseClass
};

export const validationRules = {
  password: {
    characterLength: "8 characters (20 max)",
    LetterNumber: "1 letter and 1 number",
    specialCharacter: "1 special character (Example: # ? ! $ & @)"
  },
  dob: {
    dateFormat: "MM / DD / Y - For example 12 31 1970",
    ageLimit: "Age should be 16 years or above."
  }
};

export const enterYourNameAttributes = {
  label: {
    firstName: "First name",
    lastName: "Last name"
  },
  name: {
    firstName: "firstName",
    lastName: "lastName"
  },
  placeHolder: {
    firstName: "First name",
    lastName: "Last name"
  },
  className: inputBaseClass
};

export const resetNewPasswordAttributes = {
  label: "New password",
  name: "password",
  placeHolder: "Enter new password",
  className: inputBaseClass
};

export const currentPasswordAttributes = {
  label: "Current password",
  name: "password",
  placeHolder: "Enter password",
  className: inputBaseClass
};

export const changePasswordAttributes = {
  label: "New password",
  name: "password",
  placeHolder: "Enter new password",
  className: inputBaseClass
};

export const buttonTitle = {
  continue: "Continue",
  resend: "Resend",
  resetPassword: "Reset password",
  changePassword: "Save and continue",
  addPhoto: "Add photo",
  finishProfile: "Finish profile",
  done: "Done",
  signUp: "Sign up",
  playToFund: "Play to support",
  playAgain: "Play again",
  teamUp: "Team up",
  share: "Share",
  save: "Save",
  joinWaitList: "Join waitlist"
};

export const ssoButtonDetails = {
  google: { title: "Continue with Google", color: "white", tag: "google" },
  meta: { title: "Continue with Meta", color: "blue", tag: "meta" },
  apple: { title: "Continue with Apple", color: "white", tag: "apple" }
};

export enum SocialLoginProvider {
  Google = "google",
  Facebook = "facebook",
  Apple = "apple"
}

//Bio Page
export const modalTitles = {
  addBio: "Add a bio",
  addSocialHandles: "Add your social handles"
};

export const placeholderText = {
  bio: "Add bio",
  instagram: "username",
  tiktok: "username",
  youtube: "username"
};

export const labels = {
  bio: "Bio (optional)",
  instagram: "Instagram",
  tiktok: "Tiktok",
  youtube: "Youtube"
};

export const names = {
  bio: "bio",
  instagram: "instagramId",
  tiktok: "tiktokId",
  youtube: "youtube"
};

export const onboardingText = {
  description: "Your name and profile will be shown on Stashlinks you create and play.",
  addBio: "+ Add a bio",
  addSocials: "+ Add your socials"
};

export const imageAltText = {
  userImage: "User Image",
  flashGreen: "Flash Green",
  instagram: "Instagram",
  tiktok: "Tiktok",
  youtube: "Youtube",
  add: "Add"
};
