import { IoIosArrowBack } from "react-icons/io";
import Cropper from "react-cropper";
import { buttonTitle, heading } from "../modules/Accounts/Onboarding/constant";
import Button from "./common/button";

const ImageCropper = ({ cropperRef, profileImageUri, onChangeSetInitialCrop, cancelCrop, handleCrop }: any) => (
  <div className="w-full bg-soft-black">
    <div className="flex items-start justify-center w-full">
      <div className="w-full">
        <div className="flex items-center px-2 py-3.5 h-12">
          <IoIosArrowBack className="text-grey absolute text-2xl" onClick={cancelCrop} />
          <h1 className="flex-1 font-primary text-center text-base text-neutral-50 font-bold leading-tight-19.2 tracking-tight">
            {heading.confirmLooksGood}
          </h1>
        </div>
        <div className="w-full flex flex-col items-center px-4 pt-4 gap-4">
          <Cropper
            ref={cropperRef}
            style={{ height: "calc(100vh - 210px)", width: "auto" }}
            zoomTo={0}
            zoomable={false}
            initialAspectRatio={1}
            aspectRatio={1}
            src={profileImageUri}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive
            autoCropArea={1}
            guides
            ready={onChangeSetInitialCrop}
          />
          <div className="fixed w-full bg-soft-black/40 backdrop-blur-8 left-0 bottom-0 p-4 pb-5 z-10 select-none">
            <Button title={buttonTitle.continue} onClick={handleCrop} isDisabled={false} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ImageCropper;
