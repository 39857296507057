import Cropper from "react-cropper";
import { IoIosArrowBack } from "react-icons/io";
import Button from "../../../components/common/button";
import OnboardingWrapper from "../../../containers/onboardingWrapper";
import { routes } from "../../../routes/routes.constant";
import profilePhoto from "./../../../assets/images/profile-photo-icon.svg";
import { buttonTitle, heading } from "./constant";
import useAddPhoto from "./hooks/useAddPhoto";
import Loader from "../../../components/common/loader/loader";

const AddPhoto = () => {
  const { onAddPhotoButtonHandler, handlePhotoChange, fileInputRef, cropperRef, error, profileImageUri, handleCrop, cancelCrop, loading } =
    useAddPhoto();

  const onChangeSetInitialCrop = () => {
    if (cropperRef.current && cropperRef.current.cropper && profileImageUri) {
      const cropper = cropperRef.current.cropper;
      cropper.setCropBoxData({
        width: cropper.getContainerData().width,
        height: cropper.getContainerData().height,
        top: 0,
        left: 0
      });
      cropper.setCanvasData({
        width: cropper.getContainerData().width,
        height: cropper.getContainerData().height,
        top: 0,
        left: 0
      });
    }
  };

  if (loading)
    return (
      <div className="h-[calc(100vh-80px)] w-screen flex justify-center items-center  bg-soft-black">
        <Loader />
      </div>
    );

  return (
    <>
      {profileImageUri ? (
        <>
          <div className="w-full bg-soft-black">
            <div className="flex items-start justify-center w-full">
              <div className="w-full">
                <div className="flex items-center px-2 py-3.5 h-12">
                  <IoIosArrowBack className="text-grey absolute text-2xl" onClick={cancelCrop} />
                  <h1 className="flex-1 font-primary text-center text-base text-neutral-50 font-bold leading-tight-19.2 tracking-tight">
                    {heading.confirmLooksGood}
                  </h1>
                </div>
                <div className="w-full flex gap-4 flex-col justify-center items-center px-4 pt-4">
                  <div className="flex justify-center items-center">
                    <Cropper
                      ref={cropperRef}
                      style={{ height: "calc(100vh - 210px)", width: "auto" }}
                      zoomTo={0}
                      zoomable={false}
                      initialAspectRatio={1}
                      aspectRatio={1}
                      src={profileImageUri}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={0.9}
                      checkOrientation={false}
                      guides={true}
                      ready={() => onChangeSetInitialCrop()}
                    />
                  </div>
                  <div className="fixed w-full bg-soft-black/40 backdrop-blur-8 left-0 bottom-0 p-4 pb-5 z-10 select-none">
                    <Button title={buttonTitle.continue} isDisabled={false} onClick={handleCrop} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <OnboardingWrapper heading={heading.addPhoto} isTabVisible={true} tabId={2} totalTab={4} backButtonPath={routes.userDob}>
          <div className="w-full pt-14 flex justify-center items-center">
            <div className="w-full max-w-xs px-10">
              <input
                id="photoInput"
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handlePhotoChange}
              />
            </div>
          </div>
          <div className="mt-24 flex justify-center items-center">
            <img src={profilePhoto} alt={profilePhoto} className="border-0 shadow-none" />
          </div>
          {error ? <p className="text-xs p-4 font-medium text-error mx-0.5 font-secondary text-left">{error}</p> : <span></span>}
          <div className="fixed w-full bg-soft-black/40 backdrop-blur-8 left-0 bottom-0 p-4 pb-5 z-10 select-none">
            <Button title={buttonTitle.addPhoto} isDisabled={false} onClick={onAddPhotoButtonHandler} />
          </div>
        </OnboardingWrapper>
      )}
    </>
  );
};

export default AddPhoto;
